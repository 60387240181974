@import "../../../variables";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";

.mobile2r-1c {
    .row {
        .region {
            width: 100%;

            @include media-breakpoint-up(lg) {
                padding-left: 12px;
                padding-right: 12px;
            }

            &.first-column {
                max-width: var(--mobile-column1-width);

                @include media-breakpoint-up(lg) {
                    max-width: var(--desktop-column1-width);
                }
            }

            &.second-column {
                max-width: var(--mobile-column2-width);

                @include media-breakpoint-up(lg) {
                    max-width: var(--desktop-column2-width);
                }
            }

            .herobanner {
                @include media-breakpoint-down(lg) {
                    border-radius: 0;
                }
            }
        }
    }
}
